import React from "react"
import Hblog from "./Hblog"
import Hero from "./hero/Hero"
import Hprice from "./Hprice"
import Hstops from "./Hstops"
import Hschedule from "./Hschedule"
import Hpayment from "./Hpayment"

const Home = () => {
  return (
    <>
      <Hero />
      <Hblog />
      <Hstops/>
      <Hschedule/>
      <Hprice />
      <Hpayment/>
    </>
  )
}

export default Home
