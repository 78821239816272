import React from "react"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
          </div>
          <div className='right row'>
          </div>
        </div>
      </section>
      <div className='legal'>
        <p>
          Copyright ©2024 All rights reserved | Powered with <i className='fa fa-heart'></i> by Morgana
        </p>
      </div>
    </>
  )
}

export default Footer
