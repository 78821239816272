import "./App.css"
import Header from "./components/common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Pricing from "./components/pricing/Pricing"
import Blog from "./components/blog/Blog"
import Footer from "./components/common/footer/Footer"
import Home from "./components/home/Home"
import Schedule from "./components/schedule/Schedule"
import Stops from "./components/stops/Stops"
function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/routes' component={Blog} />
          <Route exact path='/schedule' component={Schedule} />
          <Route exact path='/stops' component={Stops} />
          <Route exact path='/pricing' component={Pricing} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App
