import React from "react"
import "../blog/blog.css"
import { schedule } from "../../dummydata"
import Heading from "../common/heading/Heading"

const Hschedule = () => {
  return (
    <>
      <section className='blog'>
        <div className='container'>
          <Heading subtitle='' title='Schedule' />
          <div className='grid2'>
            {schedule.slice(0, 6).map((val) => (
              <div className='items shadow'>
                <div className='img'>
                  <img src={val.cover} alt='' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Hschedule
