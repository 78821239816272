import React from "react"
import "../blog/blog.css"
import { payment } from "../../dummydata"
import Heading from "../common/heading/Heading"

const Hpayment = () => {
  return (
    <>
      <section className='blog'>
        <div className='container'>
          <Heading subtitle='' title='Payment' />
          <div className='grid4'>
            {payment.slice(0, 2).map((val) => (
              <div className='items shadow'>
                <div className='img'>
                  <img src={val.cover} alt='' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Hpayment
